import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import moment from "moment";

import React, { useState, useEffect } from "react";

function SectionTable() {
  var currentDate = moment(new Date()).format("YYYY-MM-DD");
  var prevDate = moment(new Date()).subtract(1, "days").format("YYYY-MM-DD");

  var currentMonthYear = moment(new Date()).format("MMM,YYYY");
  var current = moment(currentDate).format("ddd Do");
  var prevs = moment(prevDate).format("ddd Do");
  const currentTime = moment().format("HH:mm");
  const [gameResult, setGameResult] = useState([]);
  const [datagame, setDataFor] = useState([]);
  const [data, setGameData] = useState([]);

  useEffect(() => {
    fetch("https://api.sattakingvip.co.in/getData", {
      method: "POST", // or 'PUT' depending on your requirements
      headers: {
        "Content-Type": "application/json", // specify the content type
      },
      body: JSON.stringify({
        game_name: "",
        curr_date: currentDate,
        prev_date: prevDate,
        open_time: "market_sunday_time_open",
      }),
    })
      .then((response) => response.json())
      .then((json) => setGameResult(json))

      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    setGameData(gameResult);
  }, [gameResult]);

  /////
  const getTodayResult = (gameData) => {
    const itemTime = moment(gameData.open_time, "HH:mm");
    const currentMoment = moment(currentTime, "HH:mm");

    if (gameData?.curr_date?.date === currentDate) {
      return currentMoment.isSameOrAfter(itemTime)
        ? gameData?.curr_date?.result || ""
        : "";
    }
    return "";
  };

  return (
    <div className="section-2">
      <div className="col">
        <div className="content-heading text-center">
          <h3>Satta King Fast Results of {currentMonthYear}</h3>
        </div>
      </div>
      <div className="table-responsive">
        <table className="">
          <thead className="thead-dark">
            <tr>
              <th scope="col" className="">
                Game Name
              </th>
              <th scope="col" className="">
                {current}
              </th>
              <th scope="col" className="">
                {prevs}
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.map((gameData, gameIndex) => (
              <tr id="" key={gameIndex}>
                <td className="">
                  {gameData.game_name}
                  <br />
                  <span>{gameData.open_time}</span>
                </td>
                <td className="text-end">
                  <span>{getTodayResult(gameData) ||"undeclared"}</span>
                </td>
                <td className="text-end">
                  {gameData?.prev_date?.result || "NULL"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default SectionTable;
