import logo2 from "./skf-logo.svg";
import "./App.css";
import "./media.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import moment from "moment-timezone";
import trackVisitor from "./utilities/tracker";
import { useEffect, useState } from "react";
import AdvertisementComponent from "./utilities/advertismentComponent";
import { Helmet } from "react-helmet";
// import {useLocation} from "react-router-dom"
import DayWise from "./utilities/dayWise";
function App() {
  const [data, setData] = useState([]); // State to store data fetched from backend
  const [datagame, setDataFor] = useState([]); // State to store processed data for display
  // const currentTime = moment().format("HH:mm");
  const [currentTime, setCurrentTime] = useState(moment().tz("Asia/Kolkata").format("HH:mm:ss"));

  // const location = useLocation();
  // const isDisclaimer = location.pathname.includes("/disclaimer");
  // const isAbout = location.pathname.includes("/about-us");
  // const isPrivacy = location.pathname.includes("/privacy-policy");

  const todayDate = moment(new Date()).tz("Asia/Kolkata").format("lll");
  var currentDate = moment(new Date()).tz("Asia/Kolkata").format("YYYY-MM-DD");
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment().tz("Asia/Kolkata").format("HH:mm:ss"));
    }, 1000); // Update every second
  
    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, []);
  
  var prevDate = moment(new Date())
    .subtract(1, "days")
    .tz("Asia/Kolkata")
    .format("YYYY-MM-DD");

  // const [gamedata, setGameData] = useState([]);
  useEffect(() => {
    trackVisitor();
  }, []);

  useEffect(() => {
    fetch("https://api.sattakingvip.co.in/getData", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        game_name: "",
        curr_date: currentDate,
        prev_date: prevDate,
        open_time: "market_sunday_time_open",
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        // Sort data based on open_time
        const sortedData = json.sort((a, b) => {
          const timeA = moment(a.open_time, "HH:mm");
          const timeB = moment(b.open_time, "HH:mm");
          return timeA.diff(timeB);
        });

        // Set sorted data into state
        setData(sortedData);
      })
      .catch((error) => console.error(error));
  }, [currentDate, prevDate]);

  useEffect(() => {
    if (data?.length > 0) {
      const currentMoment = moment(currentTime, "HH:mm");
  
      const processedData = data.map((item) => {
        const itemTime = moment(item.open_time, "HH:mm");
        const resultAvailable = item?.curr_date?.result ? true : false;
  
        return {
          gameName: item.game_name,
          result: resultAvailable ? item?.curr_date?.result : "wait",
          openTime: item.open_time,
          isAvailable: resultAvailable,
          itemTime: itemTime,
        };
      });
  
      const sortedProcessedData = processedData.sort((a, b) => 
        a.itemTime.diff(b.itemTime)
      );
  
      const availableResults = sortedProcessedData.filter((item) => item.isAvailable);
      const upcomingRecords = sortedProcessedData.filter((item) => !item.isAvailable);
  
      let recordsToDisplay = [];
  
      if (availableResults.length > 0) {
        recordsToDisplay = [...availableResults];
  
        const lastAvailableIndex = sortedProcessedData.indexOf(
          availableResults[availableResults.length - 1]
        );
        const nextRecord = sortedProcessedData[lastAvailableIndex + 1];
        if (nextRecord) {
          recordsToDisplay.push(nextRecord);
        }
      } else {
        recordsToDisplay = [...upcomingRecords.slice(0, 3)];
      }
  
      if (recordsToDisplay.length > 3) {
        recordsToDisplay = recordsToDisplay.slice(-3).reverse();
      }
  
      // Move "wait" items to the top
      recordsToDisplay.sort((a, b) => (a.result === "wait" ? -1 : 1));
  
      setDataFor(recordsToDisplay);
    }
  }, [data, currentTime]);
  
  const pageScroll = () => {
    const tableSection = document.getElementById("table-section");
    if (tableSection) {
      tableSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="section-1">
      {/* seo setup start */}
      <Helmet>
        <title></title>
        <meta name="description" content="sattasport, satta sport, sattaking" />
        <meta
          name="Keywords"
          content="Aking satta,Akingsatta,sattakingreal, satta king real, sattaking real, Satta King, Satta King live result, Satta king online result, Satta king online, Satta king result today, Gali result, Desawar result, Faridabad result, Gaziyabad result, Satta matka king, Satta Bazar, Black satta king, Satta king 2017, satta king 2018, Gali Leak Number, Gali Single Jodi, Black Satta Result, Desawar Single Jodi, Satta king up, Satta king desawar, Satta king gali, Satta king 2019 chart, Satta baba king, Satta king chart, Gali live result, Disawar live result, Satta Number, Matka Number, Satta.com, Satta Game, Gali Number, Delhi Satta king,"
        />
        <link rel="canonical" href="https://akingsatta.co/" />
      </Helmet>
      <div className="navbar_1">
        <a href="/">
          <img
            src={logo2}
            id="logo-img"
            title="Satta King Bazar Daily Results And Full Monthly Chart"
            alt="Satta-King-Fast.com | Super Fast Satta Results and Monthly Chart of October 2022 for Gali, Desawar, Ghaziabad and Faridabad"
          />
        </a>
      </div>
      <div className="text-center todayday p-3">
     < p className="text-center" style={{fontSize:'26px',fontWeight:'bolder',color:'yellowgreen'}}>Current Time: {currentTime}</p> {/* Display running current time */}
        <p className="text-center"style={{fontSize:'26px',fontWeight:'bolder',color:'yellowgreen'}}>{currentDate}</p> {/* Display current date */}

        <div className="text-center currentBanner mt-3">
          {datagame?.map((todayData, index) => (
            <div key={index} className="game">
              <h1 className="mb-0">{todayData?.gameName}</h1>
              <p className="  col-md-12">
                <div className=" blinking-text" style={{fontSize:'26px'}}>{todayData?.result || ""} </div>
              </p>
            </div>
          ))}
        </div>
        <DayWise dayGameData={data} />
      </div>
      <p className="s1-p1">
        🎮Daily Superfast Satta King Result of October 2022 And Leak Numbers🔢 for
        Gali, Desawar, Ghaziabad and Faridabad With Complete Satta King 2019
        Chart And Satta King 2018 Chart From Satta King Fast, Satta King Fast
        Result, Satta King Desawar 2019, Satta King Desawar 2018.
      </p>
      <div className="container-1">
        <p className="s1-p2">
          Satta-King-Fast.com is most populer gaming discussion forum for
          players to use freely and we are not in partenership with any gaming
          company.
        </p>

        <p className="s1-p3">
          ⚠️🚨कृपया ध्यान दें हमारे नेटवर्क की किसी भी वेबसाईट पर लीक गेम बेचने या
          खाईवाली से संबंधित कोई भी विज्ञापन नहीं डाला जाता है इसलिए जो भी
          व्यक्ति लीक गेम बेचने का या खाईवाली का विज्ञापन डलवाना चाहते हैं वह
          बिल्कुल भी संपर्क न करें -धन्यवाद🚨⚠️
        </p>

        <p className="s1-p4">
         🙏 नमस्कार दोस्तों, हमारी जानकारी मे आया है की कुछ लोग हमारे नाम पर आपसे
          लीक नंबर देने के लिए पैसे लेकर आपके साथ ठगी कर रहे है कृपया ऐसे लोगों
          से सावधान रहें हम कभी भी गेम के लीक के लिए किसी प्रकार से पैसे नहीं
          लेते है न अड्वान्स मे और ना गेम पास होने के बाद। ये जो भी सट्टा किंग,
          Queen, MD, CEO और मुंशी बने बैठे है इनकी दो कौड़ी की औकात नहीं है इसलिए
          बेकार मे इनपर अपनी मेहनत का पैसा न लुटाए -धन्यवाद🙏
        </p>
      </div>
      <AdvertisementComponent type="odd" />
      <p className="s1-p5" id="currentDate">
        {currentDate}
      </p>
    </div>
  );
}

export default App;
